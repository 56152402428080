const keys = {
  RETURN: {
    value: 'RETURN',
    label: 'Return',
  },
  RECOVERY: {
    value: 'RECOVERY',
    label: 'Recovery',
  },
  NEO: {
    value: 'NEO',
    label: 'NEO',
  },
  MANUAL: {
    value: 'MANUAL',
    label: 'Manual',
  },
  CSLOG: {
    value: 'CSLOG',
    label: 'CSLog',
  },
  HAVAN: {
    value: 'HAVAN',
    label: 'Havan',
  },
  VAREJO: {
    value: 'VAREJO',
    label: 'Varejo',
  },
};

const values = Object.keys(keys)
  .map(key => keys[key]);

export default {
  keys,
  values,
};
