<template>
  <div class="grid">
    <div class="col-12">
      <div class="font-medium text-3xl text-900 mb-3">
        <span class="text-primary text-5xl">/</span> Clientes
      </div>
      <div class="text-500 mb-5">Listagem de clientes</div>

      <div class="card">
        <Toast />

        <div class="flex flex-column mb-4 md:flex-row md:justify-content-between md:align-items-center">
          <div class="p-fluid mt-2 w-full md:mt-0 md:w-5">
            <Chips v-model="filters.filter" placeholder="Pesquisar" @add="loadRecords" @remove="loadRecords" />
          </div>
          <div class="mt-2 ml-auto md:mt-0">
            <Button label="Novo" icon="pi pi-plus" class="p-button p-button-text mr-2"
              @click="$router.push('/clientes/new')" />
          </div>
        </div>

        <DataTable dataKey="id" :value="records" :row-hover="true" responsiveLayout="stack" breakpoint="640px">
          <template #empty> Nenhum registro encontrado. </template>

          <template #loading> Carregando registros. Aguarde ... </template>

          <Column field="id" header="#"></Column>

          <Column field="cnpj" header="CNPJ">
            <template #body="{ data }">
              {{ $filters.cpfCnpj(data.cnpj) }}
            </template>
          </Column>

          <Column field="razaoSocial" header="Razão social"></Column>

          <Column field="nomeFantasia" header="Nome fantasia"></Column>

          <Column field="tipoIntegracao" header="Tipo de integração">
            <template #body="slotProps">
              <span>{{ tiposIntegracao[slotProps.data.tipoIntegracao]?.label }}</span>
            </template>
          </Column>

          <Column bodyClass="text-right">
            <template #body="slotProps">
              <Button icon="pi pi-pencil" class="p-button-text p-button mr-2"
                @click="$router.push('/clientes/' + slotProps.data.id)" />
              <Button icon="pi pi-percentage" class="p-button-text p-button mr-2"
                @click="$router.push(`/clientes/${slotProps.data.id}/regras`)" />
              <Button icon="pi pi-trash" class="p-button-text p-button mt-2"
                @click="confirmDeleteRecord(slotProps.data)" />
            </template>
          </Column>
        </DataTable>

        <Paginator :rows="perPage" :totalRecords="totalItems" @page="onPage"
          template="PrevPageLink PageLinks NextPageLink CurrentPageReport"
          currentPageReportTemplate="Página {currentPage} de {totalPages}">
        </Paginator>

        <Dialog v-model:visible="deleteRecordDialog" :style="{ width: '450px' }" header="Confirmar" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="record">Tem certeza que deseja excluir <b>{{ record.name }}</b>?</span>
          </div>
          <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="deleteRecordDialog = false" />
            <Button label="Excluir" icon="pi pi-trash" class="p-button-outlined p-button-danger"
              @click="deleteRecord" />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import CrudList from '../../components/abstract/CrudList.vue'
import CrudService from '@/services/crud'
import TipoIntegracao from '@/enums/tipo-integracao';

export default {
  extends: CrudList,
  data() {
    return {
      service: new CrudService('/admin/clientes'),
      tiposIntegracao: TipoIntegracao.keys,
    }
  }
}
</script>

<style scoped lang="scss">

</style>
